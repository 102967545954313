header {
    margin-top: 5rem;
    /* margin-bottom: 5rem; */
    height: calc(120vh - 5rem);
    display: grid;
    place-items: center;
    background: var(--color-white);
    transition: var(--transition);
    
}

.header__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;
}

/* =============== ME ============== */
.header__profile {  
  /* background: linear-gradient(var(--color-primary), transparent); */
  background: linear-gradient(0.25turn, var(--color-primary), #ebf8e1,var(--color-primary),); 
  background: var(--color-primary);
  width: 22rem;
  height: 19rem;
  /* position: absolute; */
  left: 50%;
  /* right: 50%; */
  transform: translateX(-50%);
  margin-top: 0rem;
  margin-bottom: .5rem;
  border-radius: 0.3rem 5rem 0.3rem 5rem;  
  overflow: hidden;
  padding: 0.4rem 1.5rem 1.5rem 1.5rem;
  /* position: relative; */
  
  
}

.header__profile:hover {
    /* background: linear-gradient(#690CC6); */
    /* background: var(--color-primary); */

    /* background: black; */
    /* border-radius: 0.3rem 5rem 0.3rem 5rem; */
    /* border-radius: 5rem 0.3rem 5rem 0.3rem; */
    
    /* background: linear-gradient(10turn, #ffdcc0, #c5cdc5 , #08869f); */
    /* border: 1.8px solid; */
    /* border-color: #4db5ff; */
    /* transform: rotate(180deg); */

  }

.header__container p {
    width: 35rem;
    text-align: center;
    
    margin: 0.5rem 0 2rem; 
    
}

.header__cta {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    
}

.header__socials {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.header__socials a {
    width: 1.8rem;
    aspect-ratio: 1/1;
    background: var(--color-light);
    color: var(--color-black);
    display: grid;
    place-items: center;
    border-radius: var(--border-radius-3);
}

.header__socials a:hover {
    background: var(--color-primary);
    color: var(--color-white);
    transform: translateX(-0.5rem);
}

.microsoft_logo {  
    width: 11rem;
    align-items: center;

    /* padding: -1rem 2rem 30px 0px; */
    /* margin-bottom: 1rem; */
  }

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
    header {
        margin-top: 0;
        height: 100vh;
    }
}


/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 600px) {
    .header__profile {
        /* width: 14rem;
        height: 11rem;
        max-width: 60%;
        max-height: 35vh; */

        /* width: 14rem;
      
        max-width: 20rem;
        margin-top: 2.5rem;
       
        max-width: 60%;
        max-height: 21vh; 
        justify-content: center;
        border-radius: 0.3rem 2.5rem 0.3rem 2.5rem;  
        align-content: center; */

        width: 70%;
        max-width: 20rem;
        max-height: 25vh;
        margin-top: 3rem;
        justify-content: center;
        border-radius: 0.3rem 4rem 0.3rem 4rem;
       
        
    }

    .header__container p {
        width: 100%;
    }

    .header__cta {
        gap: 1rem;
    }

    .header__socials {
        display: none;
    }
}


/* MEDIA QUERIES (small-screen-sizes) */
@media screen and (max-width: 389px) {
    .header__profile {
        /* width: 14rem;
        height: 11rem;
        max-width: 60%;
        max-height: 35vh; */

        /* width: 14rem;
      
        max-width: 20rem;
        margin-top: 2.5rem;
       
        max-width: 60%;
        max-height: 21vh; 
        justify-content: center;
        border-radius: 0.3rem 2.5rem 0.3rem 2.5rem;  
        align-content: center; */

        width: 70%;
        max-width: 20rem;
        max-height: 29vh;
        margin-top: 7rem;
        justify-content: center;
        border-radius: 0.3rem 4rem 0.3rem 4rem;
       
        
    }

    .header__container p {
        width: 100%;
    }

    .header__cta {
        gap: 1rem;
    }

    .header__socials {
        display: none;
    }
}