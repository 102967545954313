.contact__container {
    width: fit-content;
    display: flex;
    justify-content: center;
    gap: 3rem;
}

.contact__container a {
    width: 5rem;
    aspect-ratio: 1/1;
    color: white;
    background: var(--color-primary);
    display: grid;
    place-items: center;
    font-size: 2rem;
    border: 2px solid transparent;
    border-radius: var(--border-radius-1);
}

.contact__container a:hover {
    background: transparent;
    border-color: var(--color-primary);
    color: var(--color-primary);
    transform: translateY(-0.5rem);
}

.para{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

/* Styles for form container */
.form__container {
    width: 50%; /* Adjust width as needed */
    margin: 2rem auto;
    margin-bottom: 2rem;
    margin-top: 0;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius-1);
    text-align: center;
  }
  
  

  /* Styles for form groups */
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  /* Styles for labels */
  label {
    display: block;
    font: poppins;
    /* color: #262428; */
    font-size: 14.4px;
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* Styles for input fields */
input[type="text"],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input#email{
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

  /* Styles for submit button */
  .submit-btn {
    font-family: 'Montserrat', sans-serif; /* Set font family to Montserrat */
    font-size: 14.4px; /* Set font size to 14.4px */
    padding: 10px 20px;
    background-color: var(--color-primary);
    color: #fff;
    border: var(--color-primary);
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .submit-btn:hover {
    background-color: white;
    color: var(--color-primary);
    border: 1.5px solid var(--color-primary);
    }
  


/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {

    .form__container{
        width: 90%;
    }

    .contact__container {
        gap: 1.5rem;
    }

    
}